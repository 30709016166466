import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap, { Expo } from 'gsap';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import PublishedSeikaGenzine from '../../components/published-seika-genzine';
import PublishedFoodDesign from '../../components/published-food-design';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/works/work.module.scss';
import MainVisualSp from '../../images/works/kyoto-uniqlo/main_visual_sp.png';
import MainVisualPc from '../../images/works/kyoto-uniqlo/main_visual_pc.jpg';

class KyotoUniqlo extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const img = document.querySelectorAll('.init_a_img');
    setTimeout(() => {
      gsap.to(img, {
        duration: 1.5,
        scale: 1,
        ease: Expo.easeOut,
      });
    }, 1000);
    const header = document.querySelector('header');
    const visual = document.querySelector('.visual');
    const visualHeight = visual.clientHeight - 64;
    window.addEventListener('scroll', () => { this.changeHeader(header, visualHeight); });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => { this.changeHeader(); });
  }

  changeHeader = (e, height) => {
    if (window.pageYOffset > height) {
      e.classList.add('work');
    } else {
      e.classList.remove('work');
    }
  };

  details = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const detailsItem = document.querySelectorAll('.init_a_detailsItem');
      this.initAnimation.list(detailsItem);
      unobserve();
    }
  };

  challengesPoint = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isChallengesPoint = 'ChallengesPoint';
      const headingChallengesPoint = document.querySelector('.init_a_headingChallengesPoint');
      const challengesPointItem = document.querySelectorAll('.init_a_challengesPointItem');
      this.initAnimation.heading(headingChallengesPoint, isChallengesPoint);
      this.initAnimation.list(challengesPointItem);
      unobserve();
    }
  };

  subCut1 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut1_bg');
      const subCutImg = document.querySelector('.init_a_subCut1_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut2 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut2_bg');
      const subCutImg = document.querySelector('.init_a_subCut2_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut3 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut3_bg');
      const subCutImg = document.querySelector('.init_a_subCut3_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut4 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut4_bg');
      const subCutImg = document.querySelector('.init_a_subCut4_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  approach = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isApproach = 'Approach';
      const headingApproach = document.querySelector('.init_a_headingApproach');
      const approachItem = document.querySelectorAll('.init_a_approachItem');
      this.initAnimation.heading(headingApproach, isApproach);
      this.initAnimation.list(approachItem);
      unobserve();
    }
  };

  projectTeam01 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isProjectTeam = 'ProjectTeam';
      const headingProjectTeam = document.querySelector('.init_a_headingProjectTeam');
      const projectTeamItem01 = document.querySelectorAll('.init_a_projectTeamItem01');
      this.initAnimation.heading(headingProjectTeam, isProjectTeam);
      this.initAnimation.list(projectTeamItem01);
      unobserve();
    }
  };

  projectTeam02 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const projectTeamItem02 = document.querySelectorAll('.init_a_projectTeamItem02');
      this.initAnimation.list(projectTeamItem02);
      unobserve();
    }
  };

  otherWorks = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isOtherWorks = 'OtherWorks';
      const headingOtherWorks = document.querySelector('.init_a_headingOtherWorks');
      this.initAnimation.heading(headingOtherWorks, isOtherWorks);
      unobserve();
    }
  };

  render() {
    const WorkQuery = graphql`
      {
        DeviceSp: file(relativePath: { eq: "works/kyoto-uniqlo/device_sp.png" }){
          childImageSharp {
            fluid(maxWidth: 170, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        DevicePc: file(relativePath: { eq: "works/kyoto-uniqlo/device_pc.png" }){
          childImageSharp {
            fluid(maxWidth: 715, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut1: file(relativePath: { eq: "works/kyoto-uniqlo/subcut_1.png" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut2: file(relativePath: { eq: "works/kyoto-uniqlo/subcut_2.png" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut3: file(relativePath: { eq: "works/kyoto-uniqlo/subcut_3.png" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut4: file(relativePath: { eq: "works/kyoto-uniqlo/subcut_4.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const optionsDetails = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const options = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={WorkQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header isWork transition={transition} current="works" />
              <main className={`work ${Style.item}`}>
                <div className={`visual ${Style.visual}`}>
                  <figure className={`init_a_visual ${Style.visualFigure}`}>
                    <img
                      className="spTabVDisBlock init_a_img"
                      src={MainVisualSp}
                      alt="ユニクロ京都河原町通り店 パンフレット 『京都ライフジャーナル』編集制作"
                    />
                    <img
                      className="tabHPcDisBlock init_a_img"
                      src={MainVisualPc}
                      alt="ユニクロ京都河原町通り店 パンフレット 『京都ライフジャーナル』編集制作"
                    />
                  </figure>
                  <figure className={Style.visualDevice}>
                    <Img
                      className="spTabVDisBlock"
                      fluid={data.DeviceSp.childImageSharp.fluid}
                      alt="ユニクロ京都河原町通り店 パンフレット 『京都ライフジャーナル』編集制作"
                    />
                    <Img
                      className="tabHPcDisBlock"
                      fluid={data.DevicePc.childImageSharp.fluid}
                      alt="ユニクロ京都河原町通り店 パンフレット 『京都ライフジャーナル』編集制作"
                    />
                  </figure>
                </div>
                <Observer {...optionsDetails} onChange={this.details}>
                  <section className={`details ${Style.details}`}>
                    <div className={`inner ${Style.inner}`}>
                      <div className={Style.block}>
                        <div className={Style.left}>
                          <p className={`init_a_detailsCategory_text ${Style.detailsCategory}`}>パンフレット編集制作</p>
                          <h1 className={`init_a_detailsTitle_text ${Style.detailsTitle}`}>
                            京都ゆにくろ『京都ライフジャーナル』
                          </h1>
                          <p className={`init_a_detailsCaption_text ${Style.detailsCaption}`}>株式会社ユニクロ<br/>株式会社電通<br/>株式会社E</p>
                          <p className={`init_a_detailsItem ${Style.description}`}>ユニクロ京都河原町通り店がリニューアルオープンするにあたり、冊子『京都ライフジャーナル』を発行。取材・原稿執筆および編集をサポートしました。</p>
                        </div>
                        <div className={Style.info}>
                          <dl className="init_a_detailsItem">
                            <dt>メディア</dt>
                            <dd>パンフレット</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>プロジェクト期間</dt>
                            <dd>2019年8月〜</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>体裁</dt>
                            <dd>A4変形 36頁 オールカラー</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>ソリューション</dt>
                            <dd>コピーライティング／取材・原稿執筆／編集業務</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </section>
                </Observer>
                <section className="sectionContent">
                  <div className="inner">
                    <Observer {...options} onChange={this.challengesPoint}>
                      <div>
                        <h2 className="heading">
                          <span className="text textChallengesPoint">Overview</span>
                          <span className="clip clipChallengesPoint init_a_headingChallengesPoint">Overview</span>
                        </h2>
                        <p className="init_a_challengesPointItem sectionAddition">概要</p>
                        <div className={`init_a_challengesPointItem ${Style.text}`}>
                          <p>日本国内だけではなく、世界にも出店を増やしつつある、カジュアルファッションブランド「ユニクロ」。そんなユニクロが、グローバル旗艦店として、京都河原町通り店（元・ミーナ京都店）を、2019年11月にリニューアルオープンしました。店舗オープンをお知らせすると同時に、地域の人々との関係性を築く施策の一環として、パンフレット『京都ライフジャーナル』を発行。ユニクロのコンセプト“LifeWear”のまなざしをもとに京都に暮らす人々を追いかけた記録です。弊社は株式会社Eの編集サポート、取材および原稿執筆というかたちで参加しました。</p>
                        </div>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut1}>
                      <figure className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut1_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut1_img"
                          fluid={data.subCut1.childImageSharp.fluid}
                          alt="ユニクロ京都河原町通り店 パンフレット 『京都ライフジャーナル』編集制作"
                        />
                      </figure>
                    </Observer>
                    <Observer {...options} onChange={this.subCut2}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut2_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut2_img"
                          fluid={data.subCut2.childImageSharp.fluid}
                          alt="ユニクロ京都河原町通り店 パンフレット 『京都ライフジャーナル』編集制作"
                        />
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut3}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut3_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut3_img"
                          fluid={data.subCut3.childImageSharp.fluid}
                          alt="ユニクロ京都河原町通り店 パンフレット 『京都ライフジャーナル』編集制作"
                        />
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut4}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut4_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut4_img"
                          fluid={data.subCut4.childImageSharp.fluid}
                          alt="ユニクロ京都河原町通り店 パンフレット 『京都ライフジャーナル』編集制作"
                        />
                      </div>
                    </Observer>
                  </div>
                </section>

                <Observer {...options} onChange={this.approach}>
                  <section className="sectionContent">
                    <div className="inner">
                      <h2 className="heading">
                        <span className="text textApproach">Approach</span>
                        <span className="clip clipApproach init_a_headingApproach">Approach</span>
                      </h2>
                      <p className="init_a_approachItem sectionAddition">アプローチ</p>
                      <div className={`init_a_approachItem ${Style.text}`}>
                        <p>『京都ライフジャーナル』では、くるりの岸田繁さんをはじめ、京都に暮らすキーパーソン8組の方々を取材しました。ユニクロのコンセプト“LifeWear”に基づき、生き方や暮らしの価値観をドキュメンタリー形式で紹介。着飾った言葉ではなく、リアルな「日々のことば」を引き出し、発信することを心がけました。</p>
                      </div>
                    </div>
                  </section>
                </Observer>
                <section className={`sectionContent ${Style.projectTeam}`}>
                  <div className={`inner ${Style.inner}`}>
                    <Observer {...options} onChange={this.projectTeam01}>
                      <div>
                        <h2 className="heading">
                          <span className="text textProjectTeam">Project <br className="spDisBlock" />team</span>
                          <span className="clip clipProjectTeam init_a_headingProjectTeam">Project <br className="spDisBlock" />team</span>
                        </h2>
                        <p className="init_a_projectTeamItem01 sectionAddition">プロジェクトチームのご紹介</p>
                        <p className={`init_a_projectTeamItem01 ${Style.intro}`}>
                          競争から共創へ。
                          <br />
                          弊社では、クライアントと
                          <br className="spDisBlock" />
                          受託会社、制作会社と
                          <br />
                          外部パートナーといった
                          <br className="spDisBlock" />
                          垣根を越え、
                          <br />
                          プロジェクトごとに最適な
                          <br className="spDisBlock" />
                          チームを編成しています。
                        </p>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.projectTeam02}>
                      <div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">プロジェクト体制</h3>
                          <div className="init_a_projectTeamItem02">
                            <dl>
                              <dt>光川 貴浩 ・河井 冬穂・早志 祐美</dt>
                              <dd>Contents</dd>
                            </dl>
                          </div>
                        </div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">クライアント</h3>
                          <p className={`init_a_projectTeamItem02 ${Style.client}`}>株式会社ユニクロ<br/>株式会社電通<br/>株式会社E</p>
                        </div>
                      </div>
                    </Observer>
                  </div>
                </section>
                <Observer {...options} onChange={this.otherWorks}>
                  <section className={`sectionContent ${Style.otherWorks}`}>
                    <h2 className="heading">
                      <span className="text textOtherWorks">Other <br className="spDisBlock" />works</span>
                      <span className="clip clipOtherWorks init_a_headingOtherWorks">Other <br className="spDisBlock" />works</span>
                    </h2>
                    <PublishedSeikaGenzine transition={transition} />
                    <PublishedFoodDesign transition={transition} />
                    <p className={`btn ${Style.btn}`}>
                      <TransitionLink to="/works" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                        <span>Explore our works</span>
                      </TransitionLink>
                    </p>
                  </section>
                </Observer>
              </main>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default KyotoUniqlo;
